













import { Store, TAwait } from '@/store/store';
import Component from 'vue-class-component';
import MissingComponent from '@/components/cards/MissingComponent.vue'
import Card from '@/components/cards/Card.vue'

@Component({
  components: {
    MissingComponent,
    Card
  }
})
export default class FinancialSnapshots extends Store {
  componentTitle = 'Financial Snapshots';

  data: TAwait<ReturnType<typeof Store.prototype.getStatistics>> = []

  async mounted(){
    await this.refresh()
  }
  /**
   * Refreshes the table data
   * @vuese
   */
  async refresh(){
    this.data = await this.getStatistics()
    
    if(!this.data){
      this.$toast.error("No Snapshots To View Right Now")
    }
  }
}
