







/**
 * Basic card component to be used throught the site that requires a styled "container"
 * 
 * @vuese
 * @group Global
 */
import Vue from 'vue'
export default Vue.extend({
    
})
