
















































// @vuese
// @group Tables
// Customers Table

import {
  EditAccount as EditAccountRoute, 
  LicenseManager as LicenseManagerRoute,
  SimplyVideoAdminLabel,
  distributorLabel,
  resellerLabel
} from '@/router'
import {Store, TAwait} from '@/store/store'
import {Component, Prop, Watch, Emit } from 'vue-property-decorator';
import TableLoading from './TableLoading.vue'
/**
 * 
 * This component renders a list of accounts that the user can see, edit and delete
 * 
 */

interface Item {
  [key: string]: any;
  manage?: boolean;
  delete?: boolean;
  license?: boolean;
}

@Component({
  components:{
    TableLoading
  }
})
export default class AccountsTable extends Store {

  @Prop() readonly type!: 'distributor' | 'reseller' | 'company'
  @Prop() readonly filterBy!: string

  items: Item[] = []
  distributorFields: Array<any> = [
    {
      key: 'business_name',
      label: 'Business',
      tdClass: 'w15'
    },
    {
      key: 'full_name',
      label: 'Name',
      tdClass: 'w10'
    },
    {
      key: 'email',
      label: 'Email',
    },
    {
      key: 'activated_at',
      label: 'Activated',
    },
    {
      key: 'resellers_count',
      label: 'Maintains',
    },
    {
      key: 'companies_count',
      label: '',
    },
    {
      key: 'licenses_count',
      label: 'Licenses',
    },
    {
      key: 'manage',
      label: '',
      tdClass: 'w10',
    },
  ]
  resellerFields: Array<any> = [
    {
      key: 'business_name',
      label: 'Business',
      tdClass: 'w25',
    },
    {
      key: 'email',
      label: 'Email',
    },
    {
      key: 'activated_at',
      label: 'Activated',
    },
    {
      key: 'view',
      label: '',
      tdClass: 'w10',
    },
  ]

  @Watch('filterBy')
  async filterChanged() {
    this.items = []
    await this.refresh()
  }

  @Watch('type')
  async typeChanged() {
    this.items = []
    await this.refresh()
  }

  async mounted(){
    await this.refresh()
  }

  /**
   * Refreshes the table data
   * @vuese
   */
  async refresh(){
    let data: TAwait<ReturnType<typeof Store.prototype.getListOfDistributors>> | 
              TAwait<ReturnType<typeof Store.prototype.getListOfResellers>> | 
              TAwait<ReturnType<typeof Store.prototype.getListOfCustomers>> 
    switch (this.type) {
      case 'distributor':
        data = await this.getListOfDistributors()
        break;
      case 'reseller':
        data = await this.getListOfResellers(this.filterBy)
        break;
      case 'company':
        data = await this.getListOfCustomers(this.filterBy)
        break;
      default:
        break;
    }
    
    if(!data){
      this.$toast.error("No Accounts To View Right Now")
    }
    else {
      const output: Item[] = []
      data.forEach((item: Item) => {
        output.push({
          ...item,
          license : item.role !== resellerLabel, // resellers can't own licenses
          manage : true,
          delete : true
          })
      })
      
      this.items = output  || []
    }
  }

  get adminResellerFields() {
    const array = [...this.resellerFields].splice(0,3)
    array.push({key: 'companies_count',label: 'Maintains'})
    return array
  }

  get fields(): Array<any> | undefined {
    if (this.type == 'distributor') {
       return this.distributorFields
    }
    else if (this.type == 'reseller' && this.isAdmin) {
      return this.adminResellerFields
    }
    else if (this.type == 'reseller') {
      return this.resellerFields
    }
  }

  get isTypeReseller(): boolean {
    return this.type === 'reseller'
  }
  /**
   * Redirects the user to another page
   * @arg {any} user - Account details
   * @vuese
   */
  manageAccount(user: {id: number | string}){
    if(!user.id){
      return this.$toast.error('There Was An Error Managing This Account')
    }
    this.$router.push(EditAccountRoute.path.replace(/:id/g, user.id.toString()))
  }
    /**
   * Redirects the user to another page
   * @arg {any} user - Account details
   * @vuese
   */
  deleteAccount(user: any){
    this.$toast.error('Not Implemented Yet')
  }
  openLicenseManagerPage(user: any){
    if(!user.id){
      this.$toast.error('There Was An Getting The Licenses of This Account')
    }
    let route = LicenseManagerRoute.path
    route = route.replace(/:id/g, user.id.toString())
    route = route.replace(/:account_id/g, user.account_id.toString())
    route = route.replace(/:type/g, user.role.toString())
    this.$router.push(route)
  }

  @Emit()
  showResellers(disti: any) {
    return disti;
  }

  @Emit()
  showCompanies(reseller: any) {
    return reseller
  }
}
