




















































// @vuese
// @group Tables
// Customers Table

import {
  EditCustomerAccount as EditCustomerAccountRoute,
  LicenseManager as LicenseManagerRoute,
  resellerLabel
} from '@/router'
import {Store} from '@/store/store'
import {Component, Prop, Watch} from 'vue-property-decorator';
import TableLoading from './TableLoading.vue'

interface Item {
  [key: string]: any;
  manage?: boolean;
  delete?: boolean;
  license?: boolean;
}

@Component({
  components:{
    TableLoading
  }
})
/**
 * 
 * This component renders a list of customers that the user can see, edit and delete
 * 
 */
export default class CustomersTable extends Store {

  @Prop() readonly filterBy!: string

  componentTitle = 'Customers Table';

  items: Item[] = []
  fields = [
    {
      key: 'business_name',
      label: 'Business',
      tdClass: 'w25'
    },
    {
      key: 'created_at',
      label: 'Created',
    },
    {
      key: 'users_count',
      label: 'Users',
    },
    {
      key: 'licenses_count',
      label: 'Licenses',
    },
    {
      key: 'manage',
      label: '',
      tdClass: 'w10'
    },
  ]

  @Watch('filterBy')
  async filterChanged() {
    this.items = []  
    await this.refresh()
  }  

  async mounted(){
    await this.refresh()
  }
  /**
   * Refreshes the table data
   * @vuese
   */
  async refresh(){
    const data = await this.getListOfCustomers(this.filterBy)
    
    if(!data){
      this.$toast.error("No Accounts To View Right Now")
    }
    else {
      const output: Item[] = []
      data.forEach((item: Item) => {
        output.push({
          ...item,
          license : item.role !== resellerLabel, // resellers can't own licenses
          manage : true,
          delete : true
        })
      })
      
      this.items = output  || []    
    }
  }

  canEdit(row:any) :boolean {
    return this.isReseller && row.item.is_trial
  }

  /**
   * Redirects the user to another page
   * @arg {any} user - Account details
   * @vuese
   */
  manageAccount(user: {id: number | string}){
    if(!user.id){
      this.$toast.error('There Was An Error Managing This Account')
    }
    else {
      this.$router.push(EditCustomerAccountRoute.path.replace(/:id/g, user.id.toString()))
    }
  }
    /**
   * Redirects the user to another page
   * @arg {any} user - Account details
   * @vuese
   */
  deleteAccount(user: any){
    this.$toast.error('Not Implemented Yet')
  }
  openLicenseManagerPage(user: any){
    if(!user.id){
      this.$toast.error('There Was An Getting The Licenses of This Account')
    }
    let route = LicenseManagerRoute.path
    route = route.replace(/:id/g, user.id.toString())
    route = route.replace(/:account_id/g, user.id.toString())
    route = route.replace(/:type/g, "provisioned_company")
    this.$router.push(route)
  }
  getStatus(item: any) {
    return item.is_pending ? 'pending' : 'verified';
  }
}
