var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('TableLoading',{attrs:{"tableId":"accounts-table","fields":_vm.fields,"items":_vm.items},on:{"update:items":function($event){_vm.items=$event}},scopedSlots:_vm._u([{key:"license-action",fn:function(ref){
var row = ref.row;
return [_c('a',{attrs:{"href":"#","disabled":!row.item.license},on:{"click":function($event){$event.preventDefault();return _vm.openLicenseManagerPage(row.item)}}},[_vm._v(" View Licenses ")])]}},{key:"resellers-action",fn:function(ref){
var row = ref.row;
return [_c('a',{attrs:{"href":"#","disabled":!row.item.license},on:{"click":function($event){$event.preventDefault();return _vm.showResellers(row.item)}}},[_vm._v(" "+_vm._s(row.item.resellers_count)+" Resellers ")])]}},{key:"companies-action",fn:function(ref){
var row = ref.row;
return [(_vm.isTypeReseller)?_c('a',{attrs:{"href":"#","disabled":!row.item.license},on:{"click":function($event){$event.preventDefault();return _vm.showCompanies(row.item)}}},[_vm._v(" "+_vm._s(row.item.companies_count)+" Companies ")]):[_vm._v(" "+_vm._s(row.item.companies_count)+" Companies ")]]}},{key:"manage-action",fn:function(ref){
var row = ref.row;
return [_c('button',{staticClass:"btnPrimary small manage_button",attrs:{"href":"#","disabled":!row.item.manage},on:{"click":function($event){$event.preventDefault();return _vm.manageAccount(row.item)}}},[_vm._v(" Manage ")])]}},{key:"view-action",fn:function(ref){
var row = ref.row;
return [_c('button',{staticClass:"btnPrimary small view_button",attrs:{"href":"#","disabled":!row.item.manage},on:{"click":function($event){$event.preventDefault();return _vm.manageAccount(row.item)}}},[_vm._v(" View "+_vm._s(_vm._f("capitalise")(row.item.role))+" ")])]}},{key:"delete-action",fn:function(ref){
var row = ref.row;
return [_c('button',{staticClass:"btnSecondary small delete_button",attrs:{"href":"#","disabled":!row.item.delete},on:{"click":function($event){$event.preventDefault();return _vm.deleteAccount(row.item)}}},[_vm._v(" Delete ")])]}},{key:"loading",fn:function(){return [_vm._v(" Loading "+_vm._s(_vm.type)+"s… ")]},proxy:true},{key:"no-data",fn:function(){return [_vm._v(" No "+_vm._s(_vm.type)+"s have been found ")]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }